import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ReactApexChart from "react-apexcharts";

import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { format } from "date-fns";
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export const TimeLineChart = React.memo(({data=[], fechaInicioAux, fechaTerminoAux}) => {
  const { t, i18n } = useTranslation();
  const [heightGrid, setheightGrid ] = useState(40);
  let inicioMoment = moment(fechaInicioAux).format("YYYY-MM-DD");
  let finMoment = moment(fechaTerminoAux).format("YYYY-MM-DD");

  let min= new Date(fechaInicioAux).getTime();
  let max= new Date(fechaTerminoAux).getTime();
  let diferenciaEnMilisegundos = max - min;
  let diferenciaEnDias = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));

  const getFillColorGrafico = (id_tipo_maquina) => {
    switch (id_tipo_maquina) {
      case 1:
        return '#5cbf3d';
      case 3:
        return "#b33b9b";
      case 4:
        return "#ff7325";
      case 5:
        return "#4185f2";
      case 8:
        return "#4185f2"
      default:
        return "#d6dce4";
    }
  }
  
  const getCategoriaMaquina = (id_tipo_maquina) => {
    switch (id_tipo_maquina) {
      case 1:
        return t("limpieza_scoop");
      case 3:
        return t("perforacion");
      case 4:
        return t("refuerzo");
      case 5:
        return "Telehandler";
      case 8:
        return "Auxiliar"
      default:
        return "#d6dce4";
    }
  }


function agruparPorDescripcion(data) {
  const groupedData = data.reduce((acc, dato) => {
    if (!acc[dato.descripcion]) {
      acc[dato.descripcion] = [];
    }

    acc[dato.descripcion].push({
      id: dato.id,
      nombre_maquina: dato.nombre_maquina,
      fecha_inicio: new Date(dato.fecha_inicio),
      fecha_final: new Date(dato.fecha_final),
    });

    return acc;
  }, {});

  return groupedData;
}


function contarOverlapPorDescripcion(maquinasAgrupadas) {
  const overlapPorDescripcion = {};
  

  // Recorre cada grupo de máquinas por descripción
  for (const descripcion in maquinasAgrupadas) {
    const maquinas = maquinasAgrupadas[descripcion];
    
     for (let i = 1; i < maquinas.length; i++) {
      const maquinaActual = maquinas[i];
        
        for (let j = 0; j < i; j++) {
        const maquinaAnterior = maquinas[j];
        
        if (
          (maquinaActual.fecha_inicio >= maquinaAnterior.fecha_inicio && maquinaActual.fecha_inicio < maquinaAnterior.fecha_final) ||
          (maquinaActual.fecha_final > maquinaAnterior.fecha_inicio && maquinaActual.fecha_final <= maquinaAnterior.fecha_final) ||
          (maquinaAnterior.fecha_inicio >= maquinaActual.fecha_inicio && maquinaAnterior.fecha_inicio < maquinaActual.fecha_final) ||
          (maquinaAnterior.fecha_final > maquinaActual.fecha_inicio && maquinaAnterior.fecha_final <= maquinaActual.fecha_final)
        ) {
          // Incrementa la cantidad de solapamientos para la descripción actual
          overlapPorDescripcion[descripcion] = (overlapPorDescripcion[descripcion] || 0) + 1;
        }
        
        }
     }

   
  }

  return overlapPorDescripcion;
}

  function procesarDataControlFrentes2() {


    let dataCombinada = data;
    

    let formatedData = dataCombinada
      .filter((dato) => dato.descripcion !== null && dato.descripcion !== undefined)
      .map((dato) => {
        let fechaInicio = new Date(dato.fecha_inicio).getTime();
        let fechaTermino = new Date(dato.fecha_final).getTime();
        return {
          x: dato.descripcion,
          y: [fechaInicio, fechaTermino],
          fillColor: getFillColorGrafico(dato.tipo_maquina),
          categoria: getCategoriaMaquina(dato.tipo_maquina),
          maquina: dato.nombre_maquina,
        };
      });
  
    let groupedData = dataCombinada
      .filter((dato) => dato.descripcion !== null && dato.descripcion !== undefined)
      .reduce((acc, dato) => {
        if (!acc[dato.descripcion]) {
          acc[dato.descripcion] = [];
        }
        acc[dato.descripcion].push({
          nombre_maquina: dato.nombre_maquina,
          fecha_final: new Date(dato.fecha_final),
          fecha_inicio:new Date(dato.fecha_inicio),
          id:dato.id,
          nombre_maquina:dato.nombre_maquina
        });
  
        return acc;
      }, {});

      let groupedDataForCount =agruparPorDescripcion(dataCombinada)
  
      
    let result = Object.keys(groupedData).map((descripcion) => {
      const machines = groupedData[descripcion];
    
      // Ordenar las máquinas por fecha de término de manera descendente
      const sortedMachines = machines.sort((a, b) => {
        const fechaFinA = a.fecha_final;
        const fechaFinB = b.fecha_final;
        if(descripcion == "RP9524W"){
        }
        // Comparar solo fechas y horas sin milisegundos
        return fechaFinB.getTime() - fechaFinA.getTime();
      });
      
    
      // Tomar el primer elemento del array ordenado (última máquina por fecha y hora)
      const lastMachine = sortedMachines[0];
    
      return {
        descripcion: descripcion,
        lastMachineName: lastMachine.nombre_maquina,
        lastMachineEndDate:format(lastMachine.fecha_final, 'dd-MM-yyyy HH:mm:ss')
      };
    });
    
    
    
    

    let overlapDescripcionResult = contarOverlapPorDescripcion(groupedDataForCount)
    let valoresEnArrayOverlap = Object.values(overlapDescripcionResult);
    let maxValorEnArrayOverlap
    if(valoresEnArrayOverlap.length > 0){

      maxValorEnArrayOverlap = (Math.max(...valoresEnArrayOverlap) || 1);
    }else{
      maxValorEnArrayOverlap = 1
    }
    
    return { formatedData, lastMachines: result, maxOverlap: maxValorEnArrayOverlap || 1 };
  }
  
  useEffect(() => {//(0.73 * procesarDataControlFrentes2().maxOverlap))
    if((((procesarDataControlFrentes2().lastMachines.length*52))* (1 * procesarDataControlFrentes2().maxOverlap)) / procesarDataControlFrentes2().lastMachines.length >= 0){
      setheightGrid((((procesarDataControlFrentes2().lastMachines.length*52))* (1 * procesarDataControlFrentes2().maxOverlap)) / procesarDataControlFrentes2().lastMachines.length) 
    }
  //procesarDataControlFrentes2().lastMachines.length*62))* (1 * procesarDataControlFrentes2().maxOverlap)
  }, [procesarDataControlFrentes2()])
  
  
const series =[{data:procesarDataControlFrentes2().formatedData}]

      const dataLength = data.length; // Número de filas en tu gráfico
      const formatedDataLength = procesarDataControlFrentes2().formatedData.length; // Número de filas en formatedData
      const lastMachinesLength = procesarDataControlFrentes2()?.lastMachines?.length ? procesarDataControlFrentes2().lastMachines.length : 0; 
      const maxOverlap = procesarDataControlFrentes2().maxOverlap;
      const CalculoHeightApexChart = (lastMachinesLength*62) * (maxOverlap);
      /*
      xaxis: {
          min: min,
          max: max,
          type: "datetime",
            axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false
          },
          labels:{
            datetimeUTC: false,
            format: 'HH:mm',
            style: {
              fontSize: "12px"
            },
          },
          tickPlacement:'on'
        }
      */
      const options= {
        chart: {
          height: 450,
          type: "rangeBar",
          toolbar: {
            tools:{
              download:false,
              pan:false
            }
          }
        },
        grid:{
          show:true,
          xaxis:{
            lines:{
              show:true
            }
          },
          yaxis:{
            width:'100%',
            lines:{
              show:false
            }
          },
          row: {
            colors: ['#f3f4f5', '#fff'],
            opacity: 1
          }
        },
        xaxis: {
          type: "datetime"
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "80%",
            rangeBarOverlap: true,
          }
        },
        tooltip: {
          enabled:true,
          fixed: { position: "topLeft" },
          custom({series, seriesIndex, dataPointIndex, w}){
              let baseDireccion = w.config.series[seriesIndex].data[dataPointIndex];
              let fechaInicio = format(new Date(baseDireccion.y[0]), "yyyy-MM-dd HH:mm")
              let fechaTermino = format(new Date(baseDireccion.y[1]), "yyyy-MM-dd HH:mm")

              if(!w.config.series[seriesIndex]?.data[dataPointIndex]?.label){
                return (
                  '<div style="width: 100%; height: 100%;padding:20px; display:flex; flex-direction:column;">' +
                    '<span style="font-weight:bold">' +
                      baseDireccion.x +
                    "</span>" +
                    '<span>' +
                      'Maquina: '+ baseDireccion.maquina +
                     '</span>' +
                    '<span>' +
                      'Duración: '+ fechaInicio+" hrs - " + fechaTermino+ "hrs"+
                     '</span>' +
                  "</div>"
                )
              }else{
                return(
                  '<div style="width: 100%; height: 100%; display:flex; flex-direction:column; padding:20px">' +
                     '<span style="font-weight:bold">' +
                        baseDireccion.x +
                     '</span>'+ 
                     +
                     '<span style="font-weight:bold">' +
                        baseDireccion.categoria + ": " + baseDireccion.label +
                     '</span>' +
                     '<span>' +
                      'Duración: '+ fechaInicio+" hrs - " + fechaTermino+ "hrs"+
                     '</span>' +
                  "</div>"
                )
              }
          },
          x: {
            format: 'HH:mm',
          },
          y: {
            
          }
    
        },
        xaxis: {
          min:min,
          max:max,
          type: "datetime",
            axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false
          },
          labels:{
            datetimeUTC: false,
            style: {
              fontSize: "12px"
            },
          },
          tickPlacement:'on'
        },
        yaxis: {
          labels: {
            style: {
              fontWeight: "bold",
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          style: { colors: ["#fff"] },
          formatter: function (val, opt) {
            return opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
              .label;
          },
          dropShadow: { enabled: false },
          offsetX: 1,
          textAnchor: 'middle'
        }
      }
     
      const options2 = {
        chart: {
          type: "rangeBar",
          height:"1000px",
          toolbar: {
            tools:{
              download:false,
              pan:false
            }
          }
        },
        grid:{
          show:true,
          xaxis:{
            lines:{
              show:true
            }
          },
          yaxis:{
            width:'100%',
            lines:{
              show:false
            }
          },
          row: {
            colors: ['#f3f4f5', '#fff'],
            opacity: 1
          }
        }
        ,
        tooltip: {
          enabled:true,
          custom({series, seriesIndex, dataPointIndex, w}){
              let baseDireccion = w.config.series[seriesIndex].data[dataPointIndex];
              let fechaInicio = format(new Date(baseDireccion.y[0]), "yyyy-MM-dd HH:mm")
              let fechaTermino = format(new Date(baseDireccion.y[1]), "yyyy-MM-dd HH:mm")

              if(!w.config.series[seriesIndex]?.data[dataPointIndex]?.label){
                return (
                  '<div position:absolute;left:0;bottom:0; style="width: 100%; height: 100%;padding:20px; display:flex; flex-direction:column;">' +
                    '<span style="font-weight:bold">' +
                      baseDireccion.x +
                    "</span>" +
                    '<span>' +
                      'Maquina: '+ baseDireccion.maquina +
                     '</span>' +
                    '<span>' +
                      'Duración: '+ fechaInicio+" hrs - " + fechaTermino+ "hrs"+
                     '</span>' +
                  "</div>"
                )
              }else{
                return(
                  '<div position:absolute;left:0;bottom:0; style="width: 100%; height: 100%; display:flex; flex-direction:column; padding:20px">' +
                     '<span style="font-weight:bold">' +
                        baseDireccion.x +
                     '</span>'+ 
                     +
                     '<span style="font-weight:bold">' +
                        baseDireccion.categoria + ": " + baseDireccion.label +
                     '</span>' +
                     '<span>' +
                      'Duración: '+ fechaInicio+" hrs - " + fechaTermino+ "hrs"+
                     '</span>' +
                  "</div>"
                )
              }
          },
          x: {
            format: 'HH:mm',
          },
          y: {
            
          }
    
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight:'100%',
            barHeight:heightGrid,
            barHeight:'90%',
            rangeBarGroupRows: true,
            rangeBarOverlap: false,
          },
        },
        xaxis: {
          min: min,
          max: max,
          type: "datetime",
            axisTicks: {
            show: true,
          },
          axisBorder: {
            show: false
          },
          labels:{
            datetimeUTC: false,
            format: 'HH:mm',
            style: {
              fontSize: "12px"
            },
          },
          tickPlacement:'on'
        },
        yaxis: {
          labels: {
            style: {
              fontWeight: "bold",
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: true,
          style: { colors: ["#fff"] },
          formatter: function (val, opt) {
            return opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
              .label;
          },
          dropShadow: { enabled: false },
          offsetX: 1,
          textAnchor: 'middle'
        }
      };

  return (
    <Grid container wrap='nowrap'>
      <Grid item xs={diferenciaEnDias <= 2 ? 10 : 12}>
        {
          data?.length > 0 ?
          <ReactApexChart
            // height={(((procesarDataControlFrentes2().lastMachines.length*62))* (1 * procesarDataControlFrentes2().maxOverlap))}
            //height={procesarDataControlFrentes2().lastMachines.length <= 2 ? (lastMachinesLength*62) * (maxOverlap) : (lastMachinesLength*62) * (maxOverlap)}
            // height={((lastMachinesLength)*heightGrid) +68 > 500 ? 500 : ((lastMachinesLength)*heightGrid) +68}
            height={((lastMachinesLength)*heightGrid) +68 > 200 && lastMachinesLength == 2 ? 200 : 
              ((lastMachinesLength)*heightGrid) +68 > 300 && lastMachinesLength == 3 ? 300 : 
              ((lastMachinesLength)*heightGrid) +68 > 500 ? 500 : 
              ((lastMachinesLength)*heightGrid) +68}
            options={options}
            series={series}
            type="rangeBar"
          /> :
          <></>  
        }
      </Grid>
      {
        diferenciaEnDias <= 2 &&
        <Grid item xs={3}>
          <>
            <div style={{marginTop:'30px'}}>
              {
                procesarDataControlFrentes2().lastMachines.map((lastMachine, index) =>(
                  <Grid key={index+lastMachine.lastMachineName+lastMachine.lastMachineEndDate} container alignItems={'center'} justifyContent={'space-evenly'} style={{border:'1px solid #E0E0E0',
                  height: ((lastMachinesLength) * heightGrid + 68 > 200 && lastMachinesLength == 2) ? (130 / lastMachinesLength) :
                  ((lastMachinesLength) * heightGrid + 68 > 300 && lastMachinesLength == 3) ? (230 / lastMachinesLength) :
                  (lastMachinesLength) *heightGrid +68 > 500 ? (430/(lastMachinesLength)):
                 heightGrid}}>
                      <ArrowRightIcon style={{color:'#7d98ab'}}/>
                      <Typography style={{fontWeight: "bold",fontSize:`11.5px`,color:'#2f4554'}}>
                          {lastMachine.lastMachineName}
                      </Typography>              
                      <Typography style={{fontSize:`11.5px`,color:'#2f4554'}}>
                          {lastMachine.lastMachineEndDate}
                      </Typography>
                  </Grid>
                ))
              }
            </div>
            <Grid style={{marginTop:'10px'}}>
              <Typography style={{fontSize:`12.5px`,color:'#2f4554'}}>
                {
                  data?.length > 0 ?
                  "Último equipo presente" :
                  "Sin información"
                }
              </Typography>
            </Grid>
          </>
        </Grid>
      }
    </Grid>
  )
})
