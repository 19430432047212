export const getEncuentrosControlFrentes = async (id_area_operacion,fechaInicio,fechaTermino) => {
    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        "id_area_operacion":id_area_operacion,
        "fecha_inicio":fechaInicio,
        "fecha_fin":fechaTermino
    })
    
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        // redirect: 'follow',
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/control-frentes",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }

  export const getEncuentrosControlFrentesByMaquina = async (id_area_operacion,fecha_inicio,fecha_termino,id_maquina) => {
    try{
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({id_area_operacion,fecha_inicio,fecha_termino,id_maquina});
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        // redirect: 'follow',
      };
  
      const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/control-frentes/by-maquina",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } 
    catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }

export async function getUltimosEncuentrosFrente(id_area_operacion) {
  try {
    const myHeaders = new Headers()
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`)
    myHeaders.append("Content-Type", "application/json")

    const raw = JSON.stringify({ id_area_operacion })

    const requestOptions = {
      method: 'POST',
      body: raw,
      headers: myHeaders
    }

    const req = await fetch('https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/control-frentes/ultimo-encuentro', requestOptions)

    if (!req.ok) throw new Error(req.text())

    const json = await req.json()
    
    return { success: true, data: json }
  } catch (error) {
    console.error(error)
    
    return { success: false, data: [] }
  }
}

  export const getEncuentrosControlFrentesDescargar = async (fechaInicio, fechaFin, id_area_operacion) => {
    try {
      const formData = new FormData();
      formData.append('fecha_inicio', fechaInicio);
      formData.append('fecha_fin', fechaFin);
      formData.append('id_area_operacion', id_area_operacion);
  
      var requestOptions = {
        method: 'POST',
        body: formData,
        // No es necesario configurar manualmente los encabezados para CORS
        // ya que el navegador manejará automáticamente el preflight OPTIONS
      };
  
      const res = await fetch(
        "https://jxmxxrhwbfinju46egms2latqq0ggvfn.lambda-url.us-east-1.on.aws/",
        requestOptions
      );
  
      if (!res.ok) throw new Error(res.text());
      const json = await res.json();
      return { success: true, data: json };
    } catch (e) {
      console.error(e);
      return { success: false, data: [] };
    }
  }

  export async function getMaquinasControlFrentes(id_area_operacion) {
    try {
      const myHeaders = new Headers()
      myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`)
      myHeaders.append("Content-Type", "application/json")
  
      const raw = JSON.stringify({ id_area_operacion })
  
      const requestOptions = {
        method: 'POST',
        body: raw,
        headers: myHeaders
      }
  
      const req = await fetch('https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/area-operacion/maquinas-frentes', requestOptions)
  
      if (!req.ok) throw new Error(req.text())
  
      const json = await req.json()
      
      return { success: true, data: json }
    } catch (error) {
      console.error(error)
      
      return { success: false, data: [] }
    }
  }
