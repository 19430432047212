export const getVueltasDia = async (id,fecha,controller) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion:id,
      fecha:fecha
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
      signal: controller.signal
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/dashboard/get-vueltas-dia",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getVueltasDiaByTipoMaquina = async (id,fecha,controller, id_tipo_maquina) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion:id,
      fecha,
      id_tipo_maquina
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
      signal: controller.signal
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/dashboard/get-vueltas-dia-tipo-maquina",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getMovimientosDia = async (id,fecha,controller) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion:id,
      fecha:fecha
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
      signal: controller.signal
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/dashboard/get-movimientos-dia",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getMovimientosDiaByTipoMaquina = async (id,fecha,controller,id_tipo_maquina) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion:id,
      fecha:fecha,
      id_tipo_maquina
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
      signal: controller.signal
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/dashboard/get-movimientos-dia-tipo-maquina",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getInfoGeneralMina = async (id,fecha, controller) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion:id,
      fecha:fecha
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      signal: controller.signal
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/dashboard/get-info-general-mina",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getReporteResumen = async (id,fechaInicio, fechaFin) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion: id,
      fecha_inicial:fechaInicio,
      fecha_final: fechaFin
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/produccion/resumen",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getJumboResumen = async (id,fechaInicio, fechaFin) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion: id,
      fecha_inicial:fechaInicio,
      fecha_final: fechaFin
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const res = await fetch(
        "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/jumbo/export",
        requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  }
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getReporteVueltas = async (id,fechaInicio, fechaFin) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion: id,
      fecha_inicial:fechaInicio,
      fecha_final: fechaFin
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/produccion/vueltas",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getInfoJumbo = async (idAreaOperacion,idMaquina,fecha,controller) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion:idAreaOperacion,
      id_maquina:idMaquina, 
      fecha:fecha
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
      signal: controller.signal
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/maquina/get-info-jumbo",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getAllJumbosByAreaOperacion = async (idAreaOperacion) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion:idAreaOperacion
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/jumbo/get-by-mina",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getAllBoltersByAreaOperacion = async (idAreaOperacion) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion:idAreaOperacion
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/bolters/get-by-mina",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getMetasEquiposByAreaOp = async (idAreaOperacion) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion:idAreaOperacion
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/meta/mensual",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getMovimientosGPS = async (id_area_operacion= 1, id_maquina = 560, fecha ="2023-11-02") => {
//export const getMovimientosGPS = async (id_area_operacion= 1, id_maquina = 3, fecha ="2023-10-31") => {  
try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion,
      id_maquina,
      fecha
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/maquina/get-movimientos-gps",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getGeoCercasGPS = async (id_area_operacion, controla_velocidad) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion,
      controla_velocidad
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/geocerca",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getEncuentroGPS = async (id_area_operacion, id_maquina, fecha) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion,
      id_maquina,
      fecha
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/maquina/get-encuentro-gps",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getInformacionVueltasGPS = async (id_maquina, fecha) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_maquina,
      fecha_dia:fecha
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/maquina/vueltas_gps",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}


export const getHora_ingreso_egreso = async (id_area_operacion, fecha,id_tipo_maquina, id_maquina) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion,
      fecha,
      id_tipo_maquina,
      id_maquina
    });

    var requestOptions = {
      method: 'POST',
      body: raw,
      redirect: 'follow',
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/area-operacion/hora_ingreso_egreso",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}


export const descargarControlVelocidad = async (id_area_operacion, fecha_inicio, fecha_termino) => {

  try{
    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);

    var raw = JSON.stringify({
      id_area_operacion,
      fecha_inicio,
      fecha_termino
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    const res = await fetch(
    
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/gps/descargar-exceso-velocidad",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const descargarVueltasGps = async (id_area_operacion, fecha_inicio, fecha_termino) => {

  try{
    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);

    var raw = JSON.stringify({
      id_area_operacion,
      fecha_inicio,
      fecha_termino
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/gps/descargar-vueltas",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getCargasTipoMaquina = async (id_area_operacion, fecha) => {

  try{
    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);

    var raw = JSON.stringify({
      id_area_operacion,
      fecha
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/dashboard/get-cargas-dia-tipo-maquina",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const getCargasByIdMaquina = async (id_area_operacion, fecha,id_maquina) => {

  try{
    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);

    var raw = JSON.stringify({
      id_area_operacion,
      fecha,
      id_maquina
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/maquina/get-cargas",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const descargarResumenTurnos = async (id_area_operacion, fecha_inicio, fecha_termino) => {

  try{
    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);

    var raw = JSON.stringify({
      id_area_operacion,
      fecha_inicio,
      fecha_termino
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    const res = await fetch(
    
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/dashboard/get-resumen-turno",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const descargarResumenTurnosPorGrupoBeacon = async (id_area_operacion, fecha_inicio, fecha_termino) => {

  try{
    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);

    var raw = JSON.stringify({
      id_area_operacion,
      fecha_inicio,
      fecha_termino
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow',
    };

    const res = await fetch(
    
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/dashboard/get-resumen-turno-por-grupo-beacon",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}

export const descargarResumenMantenciones = async (id, fechaInicio, fechaFin) => {
  try{
    var myHeaders = new Headers();
    myHeaders.append("x-api-key", `${process.env.REACT_APP_API_KEY}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id_area_operacion: id,
      fecha_inicial:fechaInicio,
      fecha_final: fechaFin
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const res = await fetch(
      "https://qgovn4t9he.execute-api.us-east-1.amazonaws.com/dev/produccion/mantenciones",
      requestOptions
    );

    if (!res.ok) throw new Error(res.text());
    const json = await res.json();
    return { success: true, data: json };
  } 
  catch (e) {
    console.error(e);
    return { success: false, data: [] };
  }
}