import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { usePageTimingContext } from '../../context/PageTimingContext';
import NavBar from '../../components/organisms/NavBar';
import {
    Container
  } from "@material-ui/core";
  import {
    Card,
    Grid,
    Link,
    Typography,
  } from "@mui/material";
  import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useTranslation } from 'react-i18next';

const iframeStyle = {
    width: '100%',
    height: '100%',
    border: 'none', 
    overflowY:'hidden'
  };

const QuickSight = ({nombreMina, endpointQuickSight}) => {
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const { sendTime } = usePageTimingContext();

    useEffect(() => {
      return () => {
        sendTime(location.pathname)
      }
    }, [])

    return (
       <>
        <NavBar />
        <Card sx={{height:"100vh"}}>
            <iframe
                style={iframeStyle}
                src={endpointQuickSight}
                allowFullScreen={true}
                height={'100vh'}
            ></iframe>
        </Card>
       </>
    )
};

export default QuickSight;