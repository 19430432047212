import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  CircularProgress
} from "@material-ui/core";
import {
  Card,
  CardMedia,
  FormControl,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import "moment/locale/es";
import NavBar from "../../components/organisms/NavBar";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import SectionHeader from "../../components/molecules/SectionHeader";
import DataThresholdingOutlinedIcon from '@mui/icons-material/DataThresholdingOutlined';

import { format } from "date-fns";
import { generateDatabaseDateTimeES, restarDias, sumarDias } from "../../commons/FormatearFecha";

import {
  getCargasTipoMaquina,
  getInfoGeneralMina,
  getVueltasDiaByTipoMaquina
} from "../../services/operaciones";

import IconoResumenDiario from '../../assets/icons/icono-resumen-diario.png';
import IconoProdTotal from '../../assets/icons/icono-prod-total.png';
import IconoEstadoEquipos from '../../assets/icons/icono-estado-equipos.png';
import IconoControlTiempo from '../../assets/icons/icono-control-tiempo.png';
import IconoProdEquipoCamion from '../../assets/icons/icono-prod-equipo-camion.png';
import IconoProdEquipoLHD from '../../assets/icons/icono-prod-equipo-lhd.png'
import IconoProdPortales from '../../assets/icons/icono-prod-portal.png';

import ImagenScoop from "../../assets/images/lhd.png";
import ImagenCamion from "../../assets/images/camion.png";
import ImagenJumbo from "../../assets/images/jumbo.png";
import ResumenDiarioCard from "./ResumenDiarioCard";

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import CategoricalBarChart from "./CategoricalBarChart";
import CategoricalMultiBarChart from "./CategoricalMultiBarChart";

import NotificationCard from "./NotificationCard";

import PantallaCarga from "../../components/molecules/PantallaCarga";
import EstadoEquipoCard from "./EstadoEquipoCard";
import HorizontalTimeChart from "./HorizontalTimeChart";
import { useHistory, useLocation } from "react-router";

import {MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';

import IconoDescargar from '@mui/icons-material/FileDownload';
import ModalDescargaPlanilla from "../../components/organisms/ModalDescargaPlanilla";

import '../../translator';
import { useTranslation } from 'react-i18next';
import { getLocaleLanguage } from "../../commons/Idiomas";
import JumboView from "./JumboView";
import { formatoDecimales } from "../../commons/Formatos";
import UbicacionesCard from "./UbicacionesCard";
import { PopupRegistroJustificacion } from "../Justificacion/PopupRegistroJustificacion";
import { AlertaGenerica } from "../../components/molecules/AlertaGenerica";
import { useGetGruposMotivosJustificaciones } from "../../hooks/useGetGruposMotivosJustificaciones";

import { getUltimaUbicacionJustificacion } from "../../services/justificacion";
import {formatDateToUTC, formatearSoloHoraISO } from "../../commons/FormatearFecha";
import { useGetMetasEquipoByAreaOp } from "../../hooks/useGetMetasEquipoByAreaOp";
import { useContext } from "react";
import { GeneralMinaContext } from "../../context/GeneralMina";
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import TableChartIcon from '@mui/icons-material/TableChart';
import { TablaEstadosEquipos } from "./TablaEstadosEquipos";
import { HeaderEstadoEquipo } from "../../components/organisms/HeaderEstadoEquipo";
import AllMaquinasView from "./AllMaquinasView";
import { TablaDeCarga } from "../../components/organisms/TablaDeCarga";
import { LeyendasMovimientos } from "../../components/molecules/LeyendasMovimientos";
import { BreadCrumbsGenerico } from "../../components/molecules/BreadCrumbsGenerico";
import { usePageTimingContext } from "../../context/PageTimingContext";
import { getMovimientosPorMaquina } from "../../services/diario";
import { SelectorFechaFlechas } from "../../components/molecules/SelectorFechaFlechas";
import { translateMachineName } from "../../commons/utiles";



const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 30,
  borderRadius: "5px",
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#ff6600",
  },
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#00b7c4",
    borderRight: "2px solid #fff",
    borderRadius: "5px"
  },
}));

const LinearProgressOk = styled(LinearProgress)(({ theme }) => ({
  height: 30,
  borderRadius: "5px",
  [`& .${linearProgressClasses.bar}`]: {
    backgroundColor: "#85cd4b",
  },
}));

const useStyles = makeStyles({
  iconClick:{
    width:"25px",
    color: "#2f4554",
    cursor: "pointer",
    "&:hover": {
      color: "#00b7c4"
    }
  },
  root: {
    backgroundColor: "#2f4554",
    margin: "8px 0",
    color: "white",
    "&:hover": {
      backgroundColor: "#253642",
    },
    "&:disabled": {
      backgroundColor: "gray",
    },
  },
  buttonSelected: {
    backgroundColor: "#2f4554",
    color: "white",
    padding: "5px 30px",
    fontSize: "12px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "0px",
    height: "38px",
    width:"150px",
    alignSelf: "center",
    pointerEvents:"none"
  },
  buttonUnselected: {
    backgroundColor: "#f4f4f4",
    padding: "5px 30px",
    fontSize: "12px",
    textTransform: "none",
    borderRadius: "4px",
    margin: "0px",
    height: "38px",
    width:"150px",
    alignSelf: "center",
    "&:hover": {
      backgroundColor: "#00B7C4",
      color:"#fff"
    }
  },
  prodDes:{
    color: "#2f4554",
    fontSize: "12px !important",
  },
  prodValue:{
    fontSize: "20px",
    fontWeight:"bold"
  },
  datepicker: {
    width: "190px",
    backgroundColor:"white",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00B7C4"
    },
    "& .MuiInputBase-root": {
      backgroundColor: "#ffffff",
      borderColor: "error.main",
      "& .MuiButtonBase-root": {

        borderColor: "error.main"

      },
      "& .MuiInputBase-input": {
        backgroundColor: "#ffffff",
        borderColor: "error.main",
        /* fontSize:"14.4px" */
      }
    },

    '& .MuiOutlinedInput-root': { 
      '&:hover fieldset': {
          borderColor: "#00B7C4",
      }
    },
  }
});

const Operaciones = ({idMina,nombreMina, rutaMina, esMinaSubterrane = true}) => {
  const classes = useStyles();
  const location = useLocation();
  const { sendTime } = usePageTimingContext();
  let history = useHistory();
  const initialRender = useRef(true);
  const initialRenderIdMina = useRef(true);
  const { t, i18n } = useTranslation();
  
  const {onHandleSetDataGeneral, ListadoMaquinas, loadingMaquinas} = useContext(GeneralMinaContext);
  const {metas} = useGetMetasEquipoByAreaOp(idMina);
  const flotas = {
    Camion:{
      id:0,
      name: "Dumpers",
      img: ImagenCamion
    },
    LHD:{
      id:1,
      name: "Scoops",
      img: ImagenScoop
    },
    Jumbo:{
      id:3,
      name: "Jumbos",
      img: ImagenJumbo
    },
    Bolter:{
      id:4,
      name:"Bolters",
      img:ImagenJumbo
    },
    Telehandler:{
      id:5,
      name:"Telehandlers",
      img:ImagenCamion
    },
    Hormigonera:{
      id:6,
      name:"Hormigoneras",
      img:ImagenCamion
    },
    Extractor:{
      id:7,
      name:"Extractores",
      img:ImagenCamion
    },
    Auxiliar:{
      id:8,
      name:"Auxiliares",
      img:ImagenCamion
    },
    todos:{
      id:9,
      name:"todos",
      img:ImagenCamion
    },

  }

  const seriesVueltasVacias = {
    categorias:[],
    vueltas:[],
    toneladas:[],
    cargas:[]
  }

  const turnoTodos = {
    nombre: "turnotodos",
    hora_inicio: "00:00:00",
    hora_fin: "23:59:59",
    id_tipo_turno: 0,
    descripcion: "",
    orden: 0
  }

  const prodTodosDefault = {
    Camion:{
      turnotodos:{
        nombreturno: "de día",
        vueltas: []
      }
    },
    LHD:{
      turnotodos:{
        nombreturno: "de día",
        vueltas: []
      }
    },
    Telehandler:{
      turnotodos:{
        nombreturno: "de día",
        vueltas: []
      }
    },
    Hormigonera:{
      turnotodos:{
        nombreturno: "de día",
        vueltas: []
      }
    },
    Extractor:{
      turnotodos:{
        nombreturno: "de día",
        vueltas: []
      }
    },
    Auxiliar:{
      turnotodos:{
        nombreturno: "de día",
        vueltas: []
      }
    }
  }
  const [fechaActual, setFechaActual] = useState(new Date());
  const [loading, setLoading] = useState(true)
  const [showNotificacion, setShowNotificacion] = useState(false)
  const [alignment, setAlignment] = React.useState('modoTabla');

  const [listaTiposDeFlota, setListaTiposDeFlota] = useState([]);
  const [tipoFlota, setTipoFlota] = useState("todos");
  const [tipoFlotaId, setTipoFlotaId] = useState();
  const [tipoTurno, setTipoTurno] = useState("turnotodos");

  const [produccionDiaria, setProduccionDiaria] = useState({Camion:{},LHD:{},Extractor:{},Auxiliar:{}});
  const [produccionCargasDiaria, setProduccionCargasDiaria] = useState({ Dia: {
                                                                              cargas: []
                                                                          },
                                                                          Noche: {
                                                                              cargas: []
                                                                          }});
  const [produccionCargasTotal, setProduccionCargasTotal] = useState({
    nombreturno: "de día",
    cargas: []
  });
  const [prodTodos, setProdTodos] = useState(prodTodosDefault);
  const [prodDataSeriesLHD, setProdDataSeriesLHD] = useState(seriesVueltasVacias)
  const [prodDataSeriesMaquinas,setProdDataSeriesMaquinas] = useState(seriesVueltasVacias);
  const [prodDataSeriesPortales,setProdDataSeriesPortales] = useState(seriesVueltasVacias);
  const [prodDataSeriesTipoVuelta, setProdDataSeriesTipoVuelta] = useState(seriesVueltasVacias)
  const [open, setOpen] = useState(false);
  const [loadingPopUp, setLoadingPopUp] = useState(false);
  const [reloadCharts, setReloadCharts] = useState(0);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [ultimaUbicacionAlerta, setUltimaUbicacionAlerta] = useState({            
    nombre: "",
    ultima_ubicacion: null,
    fecha_ultima_ubicacion: "",
    tipo:""
  });
  const [listadoFlotaSeleccionada, setlistadoFlotaSeleccionada] = useState([])
  const handleTipoVistaEstadosMaquinas = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
      if(newAlignment == "modoTarjeta"){
        setVerEstadosEquiposEnTarjetas(true)
      }else{
        setVerEstadosEquiposEnTarjetas(false)
      }
    }
  };

  const [estadosHorometro, setEstadosHorometro] = useState([])

  const [datosMina, setDatosMina] = useState({
    portales:[],
    reglasVueltas:[],
    camiones:[],
    jumbos: [],
    scoops:[],
    bolters:[],
    telehandlers:[],
    hormigoneras:[],
    extractores:[],
    auxiliares:[], 
    turnos:{},
    produccion: {Camion:{toneladas:0},LHD:{toneladas:0}}
  })

  const [turnoSelec, setTurnoSelec] = useState(turnoTodos);
  const [ultimasVueltas, setUltimasVueltas] = useState({});
  const [ultimasCargas, setUltimasCargas] = useState({});
  const [loadingMovimientos, setLoadingMovimientos] = useState(false);
  const [loadingVueltas, setLoadingVueltas] = useState(true);
  const [openModalDescarga, setOpenModalDescarga] = useState(false);
  const [tiemposArOpMaq, setTiemposArOpMaq] = useState({});
  const [ubicacionesCard, setUbicacionesCard] = useState({});
  const [verEstadosEquiposEnTarjetas, setVerEstadosEquiposEnTarjetas] = useState(false);
  const [loadingMovimientosMaquinasParalelos, setloadingMovimientosMaquinasParalelos] = useState(false)
  const [columnasTipoVuelta, setcolumnasTipoVuelta] = useState([])
  const {gruposJustificaciones,loadingGrupoJustificaciones} = useGetGruposMotivosJustificaciones(idMina);

  const setearUbicacionesCard = (maquinas, portales) => {
    const objUbicaciones = {};
    objUbicaciones["taller"] = {
      nombreUbicacion: idMina == 16 ? "Hangar" : "Taller",
      tipo: "taller", 
      maquinas: []
    }

    portales.forEach((portal) => {
      objUbicaciones[portal.toLowerCase().replace(" ","_")] = {
        nombreUbicacion: portal,
        tipo: "",
        maquinas: []
      }
    })
    maquinas.forEach((value) => {

      if(value.grupo_ultima_ubicacion && !value?.en_taller){
        let keyUbicacion = "grupo_ultima_ubicacion"
        let tipoKey = value[keyUbicacion]?.toLowerCase().replace(" ","_");
        objUbicaciones?.[tipoKey]?.["maquinas"].push(value)
      }else if(value?.en_taller){
        objUbicaciones?.["taller"]?.["maquinas"].push(value)
      }
    })
    setUbicacionesCard(objUbicaciones)
  }

  const getNotificacionUltimaVuelta = (vuelta) => {
    return `${translateMachineName(vuelta["nombre"],t,idMina)} ${t("acaba_de_pasar_por")} ${vuelta["tipo_ultima_ubicacion"]?vuelta["tipo_ultima_ubicacion"]:"-"}`
  }

  const getHoraUltimaVuelta = (vuelta) =>{
    if((vuelta["hora_ultima_ubicacion"]!=="") && (vuelta["hora_ultima_ubicacion"])){
      return formatearSoloHoraISO(vuelta["hora_ultima_ubicacion"])
    }

    return "";
  }

  const getTonelajeTotal = (vueltas) => {
    let sumTonelajes = 0
    vueltas.forEach(vuelta => {
      sumTonelajes+=vuelta["tonelaje"]
    });
    return sumTonelajes;
  }

  const getToneladasProgramadas = () => {
    if(metas[tipoFlota]){
      if(tipoTurno==="turnotodos"){
        return metas[tipoFlota]["cantidad"];
      }
  
      const lenTurnos = Object.keys(datosMina.turnos).length;
      if(Number(lenTurnos) === 0){
        return 0;
      }
      else{
        return metas[tipoFlota]["cantidad"]/lenTurnos;
      }
    }
    else{//si el api no tiene datos

      if(tipoFlota == "Extractor"){
        return 0;
      }

      if(tipoTurno==="turnotodos"){
        return datosMina.produccion[tipoFlota]["toneladas"];
      }
  
      const lenTurnos = Object.keys(datosMina.turnos).length;
      if(Number(lenTurnos) === 0){
        return 0;
      }
      else{
        return  datosMina.produccion[tipoFlota]["toneladas"]/lenTurnos;
      }

    }
  }

  const getPorcentajeProduccion = (datosMina) => {
    const totalTon = getTonelajeTotal(getVueltasFiltradas());
    const proyectadoTon = getToneladasProgramadas();
    if(Number(proyectadoTon) === 0){
      return 100;
    }
    else if(Number(totalTon) === 0){
      return 0;
    }
    return (getTonelajeTotal(getVueltasFiltradas())/proyectadoTon)*100;
  }

  const getVueltasFiltradas = () => {
    if(tipoTurno==="turnotodos"){
      return prodTodos[tipoFlota]["turnotodos"].vueltas
    }
    return produccionDiaria[tipoFlota][tipoTurno].vueltas
  }

  const getDataSeriesCargas= (arrayVueltas, tipo, listaCategorias) => {
    //{categorias:["dumper","xd],cargas:[20,25,55,0]}
    const tipos = {
      equipo: "nombre_maquina",
      portal: "ubicacion",
      tipo_vuelta: "tipo_vuelta",
      maquina_cargadora:"maquina_cargadora"
    }

    // Defino objeto con categorias totales(incluso las vacias)
    let objSeries = {}
    listaCategorias.forEach(item => {
      // Si es una maquina, viene lista de objetos desde api y si es portal es una lista directa con nombres de portales
      const newKeyObj = tipo==="equipo"?item["nombre"]:item
      objSeries[newKeyObj]={vueltas:0,toneladas:0,ultima_vuelta:"-"}
    });

    // Sumar vueltas y tonelaje a cada categoria de objeto
    arrayVueltas.forEach(item => {
      const keyCategoria = objSeries[item[tipos["maquina_cargadora"]]];
      if(keyCategoria !== undefined){
        keyCategoria["vueltas"] +=1;
      }
    });

    const categorias = [];
    const seriesVueltas = [];
    const seriesToneladas = [];
    const seriesCargas=[];
    Object.entries(objSeries).forEach(([key,value])=>{
      categorias.push(key);
      seriesVueltas.push(value.vueltas);
      seriesToneladas.push(value.toneladas);
    })
    if(tipo==="equipo"){
      setUltimasCargas(objSeries)
    }
    return {
      categorias: categorias,
      vueltas: seriesVueltas,
      toneladas: seriesToneladas
    }
  }

  const getDataSeriesVueltas = (arrayVueltas, tipo, listaCategorias) => {
    const tipos = {
      equipo: "nombre_maquina",
      portal: "ubicacion",
      tipo_vuelta: "tipo_vuelta",
      maquina_cargadora:"maquina_cargadora"
    }

    // Defino objeto con categorias totales(incluso las vacias)
    let objSeries = {}
    listaCategorias.forEach(item => {
      // Si es una maquina, viene lista de objetos desde api y si es portal es una lista directa con nombres de portales
      const newKeyObj = tipo==="equipo"?item["nombre"]:item
      objSeries[newKeyObj]={vueltas:0,toneladas:0,ultima_vuelta:"-"}
    });
    // Sumar vueltas y tonelaje a cada categoria de objeto
    arrayVueltas.forEach(item => {
      const keyCategoria = objSeries[item[tipos[tipo]]];
      if(keyCategoria !== undefined){
        let tipo_vuelta = item?.tipo_vuelta;
        if(!keyCategoria[tipo_vuelta]){
          keyCategoria[tipo_vuelta] = 1
        }else{
          keyCategoria[tipo_vuelta] += 1
        }
        keyCategoria["vueltas"] +=1;
        keyCategoria["toneladas"] += item.tonelaje;
        if(keyCategoria["ultima_vuelta"]==="-"){
          objSeries[item[tipos[tipo]]]["ultima_vuelta"] = item.fin;
        }
        else if( (new Date(keyCategoria["ultima_vuelta"])) < (new Date(item.fin))){
          objSeries[item[tipos[tipo]]]["ultima_vuelta"] = item.fin;
        }

      }
    });

    const categorias = [];
    const seriesVueltas = [];
    const seriesToneladas = [];
    Object.entries(objSeries).forEach(([key,value])=>{
      categorias.push(key);
      seriesVueltas.push(value.vueltas);
      seriesToneladas.push(value.toneladas);
    })



    // si es maquina, se agrega estado de uktimasVueltas para completar tarjeta de estados
    if(tipo==="equipo"){
      setUltimasVueltas(objSeries)
    }

    return {
      categorias: categorias,
      vueltas: seriesVueltas,
      toneladas: seriesToneladas
    }
  }
  const procesarCargas = (data= [], listadoMaq) =>{

    let turnotodos={
      nombreturno: "de día",
      cargas: []
    }

    const resultado = {
      Dia: {
          cargas: []
      },
      Noche: {
          cargas: []
      }
  };

  const resultadoTotalAux ={
      Dia: {
          cargas: []
      },
      Noche: {
          cargas: []
      },
      turnotodos:{
        cargas: []
      }
  };

    data.forEach(item => {
      if (item.turno === "Dia") {
          resultado.Dia.cargas.push(item);
          resultadoTotalAux.Dia.cargas.push(item);
      } else if (item.turno === "Noche") {
          resultado.Noche.cargas.push(item);
          resultadoTotalAux.Dia.cargas.push(item);
      }
      resultadoTotalAux.turnotodos.cargas.push(item);
      turnotodos.cargas.push(item);
  });

    setProdDataSeriesLHD(getDataSeriesCargas(resultadoTotalAux[tipoTurno]["cargas"],"equipo",listadoMaq))
    setProduccionCargasDiaria(resultado);
    setProduccionCargasTotal(turnotodos)
    
  }

  const procesarVueltas = (prod, listPort, listMaq, reglasVueltas) => {
    // cambio para solucionar error cuando viene solo un tipo de flota (verificar)
    let prodDiaria = {
      Camion:{},LHD:{},Telehandler:{},Hormigonera:{},Extractor:{},Auxiliar:{}, ...prod
    }
    let vueltasCamion = [];
    Object.entries(prodDiaria["Camion"]).forEach(([key,value]) => {
      vueltasCamion = vueltasCamion.concat(value.vueltas)
    });
    
    let vueltasScoop = [];
    Object.entries(prodDiaria["LHD"]).forEach(([key,value]) => {
      vueltasScoop = vueltasScoop.concat(value.vueltas)
    });

    let vueltasTelehandler = [];
    Object.entries(prodDiaria["Telehandler"]).forEach(([key,value]) => {
      vueltasTelehandler = vueltasTelehandler.concat(value.vueltas)
    });

    let vueltasHormigonera = [];
    Object.entries(prodDiaria["Hormigonera"]).forEach(([key,value]) => {
      vueltasHormigonera = vueltasHormigonera.concat(value.vueltas)
    });

    let vueltasExtractor = [];
    Object.entries(prodDiaria["Extractor"]).forEach(([key,value]) => {
      vueltasExtractor = vueltasExtractor.concat(value.vueltas)
    });

    let vueltasAuxiliar = [];
    Object.entries(prodDiaria["Auxiliar"]).forEach(([key,value]) => {
      vueltasAuxiliar = vueltasAuxiliar.concat(value.vueltas)
    });


    const datosTotalProd = {
      Camion:{
        turnotodos:{
          nombreturno: "de Día",
          vueltas:vueltasCamion
        }
      },
      LHD:{
        turnotodos:{
          nombreturno: "de Día",
          vueltas:vueltasScoop
        }
      },
      Telehandler:{
        turnotodos:{
          nombreturno: "de Día",
          vueltas:vueltasTelehandler
        }
      },
      Hormigonera:{
        turnotodos:{
          nombreturno: "de Día",
          vueltas:vueltasHormigonera
        }
      },
      Extractor:{
        turnotodos:{
          nombreturno: "de Día",
          vueltas:vueltasExtractor
        }
      },
      Auxiliar:{
        turnotodos:{
          nombreturno: "de Día",
          vueltas:vueltasAuxiliar
        }
      }
    }

    setProdTodos(datosTotalProd)

    setProduccionDiaria(prodDiaria)
    if(["Camion","LHD","Telehandler","Hormigonera","Extractor","Auxiliar"].includes(tipoFlota)){

      let reglasVueltasAux = reglasVueltas?.filter((regla) => regla?.nombre_tipo_maquina == tipoFlota).map((regla) => regla.descripcion);
      setcolumnasTipoVuelta(reglasVueltasAux);
      setProdDataSeriesMaquinas(getDataSeriesVueltas(datosTotalProd[tipoFlota][tipoTurno]["vueltas"],"equipo",listMaq))
      setProdDataSeriesPortales(getDataSeriesVueltas(datosTotalProd[tipoFlota][tipoTurno]["vueltas"],"portal",listPort))
      setProdDataSeriesTipoVuelta(getDataSeriesVueltas(datosTotalProd[tipoFlota][tipoTurno]["vueltas"],"tipo_vuelta",reglasVueltasAux))
    }
    setLoadingVueltas(false)
}
  ///////////////////////////////// FIN VUELTAS /////////////////////////////////////////////////

  ///////////////////////////////// INICIO ESTADOS /////////////////////////////////////////////////
  
  const sortByDate = (arr,keyDate) => {
    const sortedArr = arr.sort(function(x, y) {
      let firstDate = new Date(x[keyDate]);
      let secondDate = new Date(y[keyDate]);
        
      if (firstDate < secondDate) return -1;
      if (firstDate > secondDate) return 1;
      return 0;
    });
    return sortedArr;
  }

  const getMinMax = (turnoSelec) => {
    const soloFecha = format(fechaActual, "yyyy-MM-dd");
    let fechaInicio = new Date(`${soloFecha} ${turnoSelec.hora_inicio}`);
    let fechaFin = new Date(`${soloFecha} ${turnoSelec.hora_fin}`);
    switch(Number(turnoSelec.id_tipo_turno)){
      case 1:
        // El turno se encuentro entre dos dias, partiendo por la madrugada del dia anterior
        fechaInicio.setDate(fechaInicio.getDate() - 1);
        return ({
          min: fechaInicio,
          max: fechaFin
        })
      case 2:
        // El turno es dentro de horario de un mismo dia
        return ({
          min: fechaInicio,
          max: fechaFin
        })
        // Case 3 representaria turno entre dos dias que termina en el dia siguiente
      case 3:
        fechaFin.setDate(fechaFin.getDate() + 1);
        return ({
          min: fechaInicio,
          max: fechaFin
        })
      default:
        // El turno tiene id 0 o distinto, por lo tanto, se deben mostrar todos los datos
        Object.values(datosMina.turnos).forEach(itemTurno => {
          switch(itemTurno.orden){
            case 0:        
              // Primer turno      
              fechaInicio = new Date(`${soloFecha} ${itemTurno.hora_inicio}`);
              if(Number(itemTurno.id_tipo_turno)===1){
                fechaInicio.setDate(fechaInicio.getDate() - 1);
              }
              break;
            case (Number(Object.keys(datosMina.turnos).length)-1):
              // Ultimo turno
              fechaFin = new Date(`${soloFecha} ${itemTurno.hora_fin}`);
              if(Number(itemTurno.id_tipo_turno)===3){
                fechaFin.setDate(fechaFin.getDate() + 1);
              }
              break;
            default:
              // Turnos intermedios no se consideran
              break;
          }
        })
        return({
          min: fechaInicio,
          max: fechaFin
        })
    }
  };

  const getDataSeriesEstadosParalelos = (estados, lim, nombreMaquina) => {
    const datosEstados = [...estados]
    const dataOperativos = [];
    const dataRalenti =[];
    const dataMantencion = [];
    const dataFueradeServ = [];
    const dataSinInfo = [];
    const dataJustificada =[];

    // Array para guardar los ultimos estados de cada maquina
    // Se itera por cada maquina
  
      // Ordena valores por fecha
      const sortedValues = sortByDate(datosEstados,"fin");
      // Horarios de turno con su fecha tipo Date
      //const minmax = getMinMax(seriesTurno);
      const fechaInicioTurno = lim.min
      const fechaFinTurno = lim.max

      // Ultima hora para analizar estados sin info
      let ultimaHoraSinInfo = lim.min
      sortedValues.forEach(estado => {

        const data = {
          x:nombreMaquina,
          y:[
            (new Date(estado.inicio)).getTime(),
            (new Date(estado.fin)).getTime()
          ],
          id_t_registro_movimiento:estado.id_t_registro_movimiento,
          id_tipo_movimiento:estado.id_tipo_movimiento
        }
        if(!estado.tipo_justificacion){
            switch(estado.movimiento){
              case "operativo":
                dataOperativos.push(data);
                break;
              case "mantencion":
                dataMantencion.push(data);
                break;
              case "fuera de servicio":
                dataFueradeServ.push(data);
                break;
              case "ralenti":
                dataRalenti.push(data);
              default:
                break
            }
        }else if(estado?.tipo_justificacion){
          (estado.tipo_justificacion.toLowerCase() == "otro") 
           ? dataJustificada.push({...data,tipo_justificacion:estado?.observacion}) 
           : dataJustificada.push({...data,tipo_justificacion:estado?.tipo_justificacion});
       }

         /* 
         Si hay una diferencia de tiempo entre la hora final del 
         estado anterior y la inicial del actual, agrega estado sin info 
         */
        if(((new Date(estado.inicio)).getTime() - ultimaHoraSinInfo.getTime())/1000>1){
          dataSinInfo.push({
            x: nombreMaquina,
            y:[
              ultimaHoraSinInfo.getTime(),
              (new Date(estado.inicio)).getTime(),
            ]
          })
        }

        ultimaHoraSinInfo = new Date(estado.fin)

      })

      /* 
      Si la maquina no tiene estados, agrega estado sininfo con el largo del inicio y fin de turno
      Si tiene datos, comprueba si hay un valle de tiempo entre el fin de ultimo estado y el fin del turno y agrega un estado sininfo
      */
      if(sortedValues.length===0){
        dataSinInfo.push({
          x: nombreMaquina,
          y:[
            fechaInicioTurno.getTime(),
            fechaFinTurno.getTime()
          ]
        })
      }
      else{
        const finEstadoFinal = new Date(sortedValues[sortedValues.length-1].fin)
        if((fechaFinTurno.getTime() - finEstadoFinal.getTime())/1000>1){
          dataSinInfo.push({
            x: nombreMaquina,
            y:[
              finEstadoFinal.getTime(),
              fechaFinTurno.getTime(),
            ]
          })
        }
      }
    let estadosDetalle = [
      {
        name: 'operativo',
        color: "#01b7c4",
        data: dataOperativos
      },
      {
        name: 'en_mantencion',
        color: "#ffbf00",
        data: dataMantencion
      },
      {
        name: 'fuera_de_servicio',
        color: "#C5283D",
        data: dataFueradeServ
      },
      {
        name: 'sin_info',
        color: "#d6dce4",
        data: dataSinInfo
      },
      {
        name:'ralenti',
        color: '#E9724C',
        data:dataRalenti
      },
      {
        name:'justificado',
        color:'#28B463',
        data:dataJustificada
      }
    ]
    return(estadosDetalle)

  } 

  const getEstadosTotalDiaParalelo = (estados) => {
    let totales = []
    Object.values(estados).forEach( valueTurno => {
      totales = [...totales, ...valueTurno]
    })
    return totales;
  }  

  const onChangeTurno = (tipo) => {
    setTipoTurno(tipo);
    const flota = tipoFlota==="Camion"?datosMina.camiones:datosMina.scoops;
    if(tipo==="turnotodos"){
      if(tipoFlota!=="Jumbo"){
      let reglasVueltasAux = datosMina?.reglasVueltas?.filter((regla) => regla?.nombre_tipo_maquina == tipoFlota).map((regla) => regla.descripcion);
      setProdDataSeriesMaquinas(getDataSeriesVueltas(prodTodos[tipoFlota][tipo]["vueltas"],"equipo", flota))
      setProdDataSeriesPortales(getDataSeriesVueltas(prodTodos[tipoFlota][tipo]["vueltas"],"portal",datosMina.portales))  
      setProdDataSeriesTipoVuelta(getDataSeriesVueltas(prodTodos[tipoFlota][tipo]["vueltas"],"tipo_vuelta",reglasVueltasAux))
    }
      setTurnoSelec(turnoTodos)
    }
    else{
      if(tipoFlota!=="Jumbo"){
      let reglasVueltasAux = datosMina?.reglasVueltas?.filter((regla) => regla?.nombre_tipo_maquina == tipoFlota).map((regla) => regla.descripcion);
      setcolumnasTipoVuelta(reglasVueltasAux);
      setProdDataSeriesMaquinas(getDataSeriesVueltas(produccionDiaria[tipoFlota][tipo]["vueltas"],"equipo", flota))
      setProdDataSeriesPortales(getDataSeriesVueltas(produccionDiaria[tipoFlota][tipo]["vueltas"],"portal",datosMina.portales))  
      setProdDataSeriesTipoVuelta(getDataSeriesVueltas(produccionDiaria[tipoFlota][tipo]["vueltas"],"tipo_vuelta",reglasVueltasAux))
    }
      setTurnoSelec(datosMina.turnos[tipo])
    }
    
  }

  const onChangeFecha =(fecha) =>{
    setFechaActual(new Date(fecha))
  }

  const onChangeFlota = (flotaSeleccionada) => {
    setLoadingVueltas(true)
    let tipo = flotaSeleccionada.target.value;
    setTipoFlota(tipo);
    if(tipo == "Camion") {
      setTipoFlotaId(2)
    }else if(tipo == "LHD"){
      setTipoFlotaId(1)
    }else if(tipo == "Telehandler"){
      setTipoFlotaId(5)
    }else if(tipo == "Hormigonera"){
      setTipoFlotaId(6)
    }else if(tipo == "Jumbo"){
      setTipoFlotaId(3)
    }else if(tipo == "Bolter"){
      setTipoFlotaId(4)
    }else if(tipo == "Extractor"){
      setTipoFlotaId(7)
    }else if(tipo == "Auxiliar"){
      setTipoFlotaId(8)
    }

    let flota;
    if(["Camion","LHD","Telehandler","Hormigonera","Extractor","Auxiliar"].includes(tipo)){
      if(tipo == "Camion") {
        flota = datosMina.camiones
      }else if(tipo == "LHD"){
        flota = datosMina.scoops
      }else if(tipo == "Telehandler"){
        flota = datosMina.telehandlers
      }else if(tipo == "Hormigonera"){
        flota = datosMina.hormigoneras
      }else if(tipo == "Auxiliar"){
        flota = datosMina.auxiliares
      }else if(tipo == "Extractor"){
        flota = datosMina.extractores
      }
      setlistadoFlotaSeleccionada(flota);

      if(tipoTurno==="turnotodos"){
        let reglasVueltasAux = datosMina?.reglasVueltas?.filter((regla) => regla?.nombre_tipo_maquina == tipoFlota).map((regla) => regla.descripcion);
        setProdDataSeriesMaquinas(getDataSeriesVueltas(prodTodos[tipo]["turnotodos"]["vueltas"],"equipo", flota))
        setProdDataSeriesPortales(getDataSeriesVueltas(prodTodos[tipo]["turnotodos"]["vueltas"],"portal",datosMina.portales)) 
        setProdDataSeriesTipoVuelta(getDataSeriesVueltas(prodTodos[tipo][tipoTurno]["vueltas"],"tipo_vuelta",reglasVueltasAux))  
      }
      else{
        let reglasVueltasAux = datosMina?.reglasVueltas?.filter((regla) => regla?.nombre_tipo_maquina == tipoFlota).map((regla) => regla.descripcion);
        setcolumnasTipoVuelta(reglasVueltasAux);
        setProdDataSeriesMaquinas(getDataSeriesVueltas(produccionDiaria[tipo][tipoTurno]["vueltas"],"equipo", flota))
        setProdDataSeriesPortales(getDataSeriesVueltas(produccionDiaria[tipo][tipoTurno]["vueltas"],"portal",datosMina.portales))
        setProdDataSeriesTipoVuelta(getDataSeriesVueltas(produccionDiaria[tipo][tipoTurno]["vueltas"],"tipo_vuelta",reglasVueltasAux))  
      }
    }else{
      if(tipo == "Jumbo"){
        flota = datosMina.jumbos
      }else if(tipo == "Bolter"){
        flota = datosMina.bolters
      }
      setlistadoFlotaSeleccionada(flota)
    }
    procesarMovimientosMaquina(flota, idMina)
  }

  const getDatosMina = (fecha, controller) => {
    const resInfo = getInfoGeneralMina(idMina,format(fecha, "yyyy-MM-dd"), controller);

    resInfo.then(({success,data}) => {
      if(success){
          const lTiposFlota = [...new Set(ListadoMaquinas.map( m => m.tipo))]
          setListaTiposDeFlota(lTiposFlota)
          const turnosConKey= data.turnos.reduce((obj, item) => ((obj[item.nombre] = item, obj)),{});
          const listScoops= ListadoMaquinas.filter(m => m.tipo === "LHD");
          const listCamiones = ListadoMaquinas.filter(m => m.tipo === "Camion");
          const listJumbos = ListadoMaquinas.filter(m => m.tipo === "Jumbo");
          const listBolters = ListadoMaquinas.filter(m => m.tipo === "Bolter");
          const listTelehandlers = ListadoMaquinas.filter(m => m.tipo === "Telehandler");
          const listHormigoneras = ListadoMaquinas.filter(m => m.tipo === "Hormigonera");
          const listExtractores = ListadoMaquinas.filter(m => m.tipo === "Extractor");
          const listAuxiliares = ListadoMaquinas.filter(m => m.tipo === "Auxiliar");

          localStorage.setItem("dataOpMina",JSON.stringify({        
            idMina: idMina,
            esMinaSubterrane, 
            nombreMina: nombreMina, 
            rutaMina: rutaMina,
            maquinas: ListadoMaquinas,
            turnosConKey
          }))
          onHandleSetDataGeneral(esMinaSubterrane,nombreMina,idMina)

          // Agrupar por ultima ubicacion
          setearUbicacionesCard(ListadoMaquinas, data.ubicaciones);

          /* 
            Cuando lleguen datos de produccion, remplazar aaray en prodConKey por data.produccion (mantener reduce())
          */
          const prodConKey = [
            {
              "id_tipo_maquina": 1,
              "nombre_tipo_maquina": "LHD",
              "toneladas": 2800
            },
            {
              "id_tipo_maquina": 2,
              "nombre_tipo_maquina": "Camion",
              "toneladas": 2400
            }
          ].reduce((obj, item) => ((obj[item.nombre_tipo_maquina] = item, obj)),{})

          const maqSorted = sortByDate(ListadoMaquinas, "fecha_ultima_ubicacion");
          const ultimaMaq = maqSorted[maqSorted.length-1];

          setUltimaUbicacionAlerta(ultimaMaq);
          setShowNotificacion(true);
          setDatosMina({
            scoops:listScoops,
            camiones:listCamiones,
            jumbos: listJumbos,
            bolters:listBolters,
            hormigoneras:listHormigoneras,
            telehandlers:listTelehandlers,
            auxiliares:listAuxiliares,
            extractores:listExtractores,
            turnos: turnosConKey,
            portales: data.portales,
            produccion: prodConKey,
            reglasVueltas:data.reglasVueltas
          })
      }
      else{
        setLoadingMovimientos(false);
        setLoadingVueltas(false);
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    localStorage.setItem("fechaObjetivo", fechaActual);
    if(tipoFlota != "todos"){
      procesarMovimientosMaquina(listadoFlotaSeleccionada, idMina)
    }
    if(initialRender.current){
      initialRender.current = false;
    } 
    else{
      const controller = new AbortController();
      setLoadingVueltas(true);
      if(ListadoMaquinas.length > 0){
        getDatosMina(fechaActual, controller);
      }
      return () => controller.abort();
    }
  },[fechaActual,ListadoMaquinas.length])

  useEffect(()=>{
    const controller = new AbortController();
    
    if (Object.keys(datosMina.turnos).length > 0 && tipoFlota != "todos") {
      setLoadingMovimientos(false);
      
      const getVueltasPromise = getVueltasDiaByTipoMaquina(idMina, format(fechaActual, "yyyy-MM-dd"), controller, tipoFlotaId);
      if(tipoFlota == "LHD"){
        getCargasTipoMaquina(idMina,format(fechaActual, "yyyy-MM-dd")).then((response) =>{
          if(response.success){
            procesarCargas(response.data,datosMina.scoops);
          }
        })
      }

      Promise.all([getVueltasPromise])
      .then(([vueltasResponse]) => {

          const vueltasSuccess = vueltasResponse.success;
          const vueltasData = vueltasResponse.data;

          if (vueltasSuccess) {
            procesarVueltas(vueltasData, datosMina.portales, tipoFlota === "Camion" ? datosMina.camiones : datosMina.scoops, datosMina.reglasVueltas);
          } else {
            setLoadingVueltas(false);
          }
    
          setLoading(false); 
        })
        .catch(error => {
          console.error("Error al ejecutar las APIs en paralelo", error);
        });
      
    }
    // setLoading(false)    
    else if(Object.keys(datosMina.turnos).length >0){
      setLoading(false)
      setLoadingVueltas(false);

    }

    return () => controller.abort();
  },[datosMina, reloadCharts,tipoFlotaId])

  useEffect(()=>{
    onHandleSetDataGeneral(esMinaSubterrane,nombreMina,idMina)
    const controller = new AbortController();
    if(initialRenderIdMina.current){
      initialRenderIdMina.current = false;
      if(ListadoMaquinas.length >0){
        getDatosMina(fechaActual, controller)
      }
    } 
    else{
      localStorage.removeItem("dataOpMaq");
      localStorage.removeItem("dataOpMina");
      localStorage.removeItem("fechaObjetivo");
      window.location.reload()
    }


    return () => controller.abort();
  },[idMina])

  useEffect(()=>{
    if(format(fechaActual, "yyyy-MM-dd")===format((new Date()), "yyyy-MM-dd")){
      const timer = setTimeout(function() {
        const f = new Date();
        setFechaActual(f);
        const controller = new AbortController();
        if(ListadoMaquinas.length > 0){
          getDatosMina(f, controller);
          setReloadCharts((prevState) => prevState += 1);
        }
      }, 300000)
       return () => {
        clearTimeout(timer);
       };
    }
   },[ListadoMaquinas])


   useEffect(() => {
     return () => {
       sendTime(location.pathname)
     }
   }, [])
   

  

   const onClickMaquina = (maq) =>{

    if(formatDateToUTC(maq?.ultimo_dato_movimiento)){
      localStorage.setItem("fechaObjetivo", formatDateToUTC(maq?.ultimo_dato_movimiento))
    }

    localStorage.setItem("dataOpMaq",JSON.stringify(maq));

    history.push(`/operaciones/maquina`);
   } 
   const [stateInicialFormJustificaciones, setstateInicialFormJustificaciones] = useState({
    fecha_inicio:'',
    fecha_termino:'',
    id_movimiento:0,
    id_registro_movimiento:0,
    id_maquina:0,
    nombre_motivo:'',
    ubicacion:''
   })

  const handleClickOpen = (id_movimiento, fecha_inicio, fecha_termino, id_t_registro_movimiento, id_maquina) => {
    setOpen(true);
    setLoadingPopUp(true);
    setstateInicialFormJustificaciones(prevState =>{
      return {
        ...prevState,
        fecha_inicio,
        fecha_termino,
        id_movimiento,
        id_t_registro_movimiento,
        id_maquina
      }
    })
    getUltimaUbicacionJustificacion(idMina, id_t_registro_movimiento)
    .then((resp) => {
      setstateInicialFormJustificaciones(prevState =>{
        return {
          ...prevState,
          ubicacion: resp.data.nombre
        }
      })
      setLoadingPopUp(false);
    })
    .catch((err) => {
      console.log(err);
      setLoadingPopUp(false);
    });

  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({ open: false, message: alert.message, severity: alert.severity });
  };
  const procesarMovimientosMaquina = async (maquinas, idMina) => {
    try {
      setloadingMovimientosMaquinasParalelos(true);
      setEstadosHorometro([]);
      let fechaMaq = format(fechaActual, "yyyy-MM-dd");
      
      let estadosUnificadosPromises = maquinas.map(async (maquina) => {
        let idMaq = maquina.id;
        let resEstados = await getMovimientosPorMaquina(idMina, idMaq, fechaMaq, fechaMaq);
  
        if (resEstados.success && resEstados.data[fechaMaq]) {
          const estadosTotales = getEstadosTotalDiaParalelo(resEstados.data[fechaMaq]);
          let nombreMaquina = resEstados.data["nombre_maquina"];
          const nuevosEstados = getDataSeriesEstadosParalelos(estadosTotales, getMinMax(turnoSelec), nombreMaquina);
  
          setEstadosHorometro(prevState => [...prevState, nuevosEstados]);
        }
      });
  
      await Promise.all(estadosUnificadosPromises);
      setloadingMovimientosMaquinasParalelos(false);
    } catch (error) {
      console.error(error);
      setloadingMovimientosMaquinasParalelos(false);
    }
  };
  return(
    <>{loading? <PantallaCarga loading={loading}/>:
    <>

      <NavBar />
      <Container>
      {/* Formulario de justificaciones */}
      
        <PopupRegistroJustificacion
          FormJustificados={false}
          classes={classes}
          loadingPopUp={loadingPopUp}
          open={open}
          gruposJustificaciones={gruposJustificaciones}
          verCheckbox = {true}
          stateInicialFormJustificaciones={stateInicialFormJustificaciones}
          setReloadCharts={setReloadCharts}
          setLoadHorizontalChart={setloadingMovimientosMaquinasParalelos}
          setOpen={setOpen}
          setAlert={setAlert}
        />
      

       <AlertaGenerica alert={alert} handleCloseAlert={handleCloseAlert} duration={5000} />

        <br/>
        <br/>

        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item>
            
          <BreadCrumbsGenerico
            dataRuta={ [
              { id: 1, endpoint: "/", texto: `Mine-Watch` },
              { id: 2, texto: `${t('minaSingular')} ${nombreMina}` },
              { id: 3, texto: idMina != 24 ? t("operaciones") : t("control_diario"), destacar:true },
            ]}
            nombreMina={nombreMina}
          />
		
          </Grid>
      
          {ultimaUbicacionAlerta["fecha_ultima_ubicacion"]!=="" &&
          <Grid item>
            <NotificationCard 
              message={getNotificacionUltimaVuelta(ultimaUbicacionAlerta)}
              time={getHoraUltimaVuelta(ultimaUbicacionAlerta)}
              show={showNotificacion}
              onClickHide={setShowNotificacion}
            />
          </Grid>
          }
        </Grid>
        
        <br/>
        <br/>

        <Grid container justifyContent="flex-end" alignItems="center" spacing={2}>
          <Grid item>
            {
              (loadingMovimientos || loadingVueltas) &&             
              <CircularProgress />

            }
          </Grid>
          <Grid item>
            <MuiPickersUtilsProvider locale={getLocaleLanguage(i18n.language)} utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.datepicker}
                format="dd/MM/yyyy"
                cancelLabel={t("cancelar")}
                size="small"
                inputVariant="outlined"
                value={fechaActual}
                onChange={setFechaActual}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>    
            <IconoDescargar
              className={classes.iconClick} 
              onClick={()=>setOpenModalDescarga(true)}
            />
          </Grid>
        </Grid>

        <br/>

        <UbicacionesCard ubicaciones={ubicacionesCard} />

        <br/>

        <Card sx={{padding:"25px"}}>
          <Grid 
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Grid 
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <div style={{width: "120px"}}>
                  <CardMedia
                    component="img"
                    image={flotas[tipoFlota].img}
                    alt="Paella dish"
                    style={{ width: "95px", margin: "0 auto" }}
                  />
                </div>
                <Grid item>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color:"#00b7c4"
                    }}
                  >
                    {
                      (flotas[tipoFlota].name == "todos") ?
                    t(flotas[tipoFlota].name+"_maquinas") :
                    [24].includes(idMina) ?
                    t(flotas[tipoFlota].name+"_traducir") :
                    t(flotas[tipoFlota].name)
                    }
                  </Typography>
                  <SelectorFechaFlechas fechaActual={fechaActual} onChangeFecha={onChangeFecha} restarDias={() => restarDias(fechaActual,1)} sumarDias={() =>sumarDias(fechaActual,1)}/>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
            <FormControl sx={{ m: 1, minWidth: 260 }}>
              <InputLabel id="demo-simple-select-helper-label">{t("tipo_flota")}</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={tipoFlota}
                label={t("tipo_flota")}
                onChange={onChangeFlota}
              >
                <MenuItem value={'todos'}>{t('todos_flotas')}</MenuItem>
                {
                  listaTiposDeFlota.map((f, index) =>{
                    return (
                      <MenuItem key={index} value={f}>{[24].includes(idMina) ? t(`flota_${f.toLowerCase()}_traducir`) : t(`flota_${f.toLowerCase()}`)}</MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>                      
            </Grid>
          </Grid>
          
          <br/>
          <br/>

          { ["Camion", "LHD", "Telehandler", "Hormigonera","Auxiliar","Extractor"].includes(tipoFlota) &&
          
          <>
          {
            ["Camion", "LHD"].includes(tipoFlota) && 
            loadingMovimientos ? 
            <Grid container justifyContent="center" alignItems="center"> 
                <CircularProgress />
            </Grid> :
            <>
              <SectionHeader 
                title={t("resumen_diario")}
                subtitle={`${t("ultima_actualizacion")} ${format(fechaActual, "HH:mm")} hrs`}
                icon={<img src={IconoResumenDiario} width="35px" alt=" Icono Resumen Diario"/>}
              />
    
              <br/>
              {
                loadingVueltas ?
                <div>
                  <Grid container justifyContent={'center'}>    
                      <CircularProgress />
                  </Grid>
                </div> :

                <Grid container spacing={1}>
                  {
                    tipoFlota != "LHD" ?
                    Object.entries(produccionDiaria[tipoFlota]).map(([key, value])=>{
                      return(
                        <Grid key={key} item lg={4} md={12} xs={12}>
                          <ResumenDiarioCard
                            id={key}
                            onClick={onChangeTurno}
                            titulo1={t("detalle_de")}
                            titulo2={t(`turno_${key.toLowerCase()}`)}
                            dataActividad={value.vueltas.length}
                            tonelaje={getTonelajeTotal(value.vueltas)}
                            isSelect={tipoTurno===key}
                            tipoFlota={tipoFlota}
                          />
                        </Grid>
                      )
                    }) :
                    Object.entries(produccionCargasDiaria).map(([key, value])=>{
                      return(
                        <Grid key={key} item lg={4} md={12} xs={12}>
                          <ResumenDiarioCard
                            id={key}
                            onClick={onChangeTurno}
                            titulo1={t("detalle_de")}
                            titulo2={t(`turno_${key.toLowerCase()}`)}
                            dataActividad={value.cargas.length}
                            tonelaje={0}
                            isSelect={tipoTurno===key}
                            tipoFlota={tipoFlota}
                          />
                        </Grid>
                      )
                    })
                  }
                  <Grid item lg={4} md={12} xs={12}>
                    <ResumenDiarioCard
                      id={"turnotodos"}
                      onClick={onChangeTurno}
                      titulo1={t("detalle_diario")}
                      dataActividad={tipoFlota != "LHD" ? prodTodos[tipoFlota]["turnotodos"].vueltas.length: produccionCargasTotal.cargas.length}
                      tonelaje={tipoFlota != "LHD" ? getTonelajeTotal(prodTodos[tipoFlota]["turnotodos"].vueltas) : 0}
                      isSelect={tipoTurno==="turnotodos"}
                      tipoFlota={tipoFlota}
                    />
                  </Grid>
                </Grid>
              }
                        
          
              <br/>
              <br/>
              <SectionHeader 
                title={t("prod_total")}
                subtitle={`${t("ultima_actualizacion")} ${format(fechaActual, "HH:mm")} hrs`}
                icon={<img src={IconoProdTotal} width="35px" alt=" Icono Produccion Total"/>}
              />
              <br/>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item lg={3} md={3} xs={3} sx={{borderRight:"2px solid #d7d7d7"}}>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                  >
                    <div className={classes.prodValue} style={{color:(getPorcentajeProduccion(datosMina)<100)?"#00b7c4":"#85cd4b"}}>
                      {getTonelajeTotal(getVueltasFiltradas())}
                    </div>
                    <div className={classes.prodDes}>
                      {t("ton_realizadas")}
                    </div>
                  </Grid>
                </Grid>
                <Grid item lg={3} md={3} xs={3} sx={{borderRight:"2px solid #d7d7d7"}}>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                  >
                    <div className={classes.prodValue} style={{color:"#2f4554"}}>
                      {formatoDecimales(getToneladasProgramadas())}
                    </div>
                    <div className={classes.prodDes}>
                      {t("ton_programadas")}
                    </div>
                  </Grid>
                </Grid>
                <Grid item lg={3} md={3} xs={3}>
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                  >
                    {
                      (getPorcentajeProduccion(datosMina)<100)?
                        <div className={classes.prodValue} style={{color:"#ff6600"}}>
                          {formatoDecimales(getToneladasProgramadas()-getTonelajeTotal(getVueltasFiltradas()))}
                        </div>
                      :
                        <div>
                          <span className={classes.prodValue} style={{color:"#ff6600"}}>
                            {"0"}
                          </span>
                          <span style={{fontSize:"20px", color:"#ff6600"}}>
                            {` (+${formatoDecimales((getToneladasProgramadas()-getTonelajeTotal(getVueltasFiltradas()))*-1)})`}
                          </span>
                        </div>               
                    } 
                    <div className={classes.prodDes}>
                      {t("ton_restantes")}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
    
              <br/>
              {
                (getPorcentajeProduccion(datosMina))<100?
                  <BorderLinearProgress 
                    variant="determinate" 
                    value={getPorcentajeProduccion(datosMina)} 
                  />
                :
                  <LinearProgressOk
                    variant="determinate" 
                    value={100} 
                  />
              }
            
            </>
          }
          
          <br/>
          <br/>
          <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item xs={11}>
              <SectionHeader 
                title={t("estado_equipos")}
                subtitle={`${t("ultima_actualizacion")} ${format(fechaActual, "HH:mm")} hrs`}
                icon={<img src={IconoEstadoEquipos} width="35px" alt=" Icono Estado Equipos"/>}
              />
            </Grid>
            <Grid  item xs={1}>
              <Stack direction={'row'}>
                  <ToggleButtonGroup
                    value={alignment}
                    exclusive
                    onChange={handleTipoVistaEstadosMaquinas}
                    aria-label="text alignment"
                  >
                    <ToggleButton value="modoTarjeta" aria-label="left aligned">
                      <ViewModuleIcon style={{color:'#2f4554'}}/>
                    </ToggleButton>
                    <ToggleButton value="modoTabla" aria-label="centered">
                      <TableChartIcon style={{color:'#2f4554'}}/>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Stack>
            </Grid>
          </Grid>
        
          <br/>
         
          {
            (!verEstadosEquiposEnTarjetas) ? (
            <Grid>
              {
                (loadingMovimientos || loadingVueltas) ?
                <TablaDeCarga
                  columnas={[
                    t("maquina"), 
                    t("estado"), 
                    t("vueltas"),
                    t("toneladas"),
                    t("ultima_ubicacion"),
                    t("fecha_ultima_ubicacion")
                  ]}
                /> :
                (listadoFlotaSeleccionada && listadoFlotaSeleccionada.length > 0)  &&
                <TablaEstadosEquipos 
                  dataVueltas={tipoFlota == "LHD" ? ultimasCargas : ultimasVueltas}
                  tiemposArOpMaq={tiemposArOpMaq}
                  isJumbo={false}
                  fechaActual={fechaActual}
                  tipoTurno={tipoTurno}
                  accion={onClickMaquina}
                  flotaSeleccionada={listadoFlotaSeleccionada}
                  tipoFlota={tipoFlota}
                  idMina={idMina}
                  columnasTipoVuelta={columnasTipoVuelta}
                /> 
              }
            </Grid>
            ) :

            <>
             <HeaderEstadoEquipo listadoFlotaSeleccionada={listadoFlotaSeleccionada}/>
            <br/>
            <br/>

            <Grid container spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              {
                listadoFlotaSeleccionada?.length > 0 && 
                listadoFlotaSeleccionada.map((maq,index) => (
                <Grid key={index} item lg={4} md={6} xs={12}>
                    <EstadoEquipoCard
                      nombre={translateMachineName(maq?.nombre,t,idMina)}
                      estado={maq?.id_tipo_movimiento}
                      turno={getMinMax(turnoSelec)}
                      vueltas={ultimasVueltas[maq?.nombre]?.vueltas}
                      toneladas={ultimasVueltas[maq.nombre]?.toneladas}
                      powerPackage={formatoDecimales(tiemposArOpMaq[maq.id]?.[format(fechaActual,"yyyy-MM-dd")]?.[tipoTurno]?.["encendido"] || 0)}
                      ultimaUbicacion={maq?.tipo_ultima_ubicacion}
                      onClick={onClickMaquina}
                      tiempoUltimaUbicacion={maq?.hora_ultima_ubicacion}
                      allMaqData={maq}
                    />
                  </Grid>
                ))              
              }

            </Grid>
            </>
          }


          <br/>
          <br/>
          <SectionHeader 
            title={t("control_de_tiempo")}
            subtitle={`${t("ultima_actualizacion")} ${format(fechaActual, "HH:mm")} hrs`}
            icon={<img src={IconoControlTiempo} width="35px" alt=" Icono Control de Tiempo"/>}
          />
          <br/>
          <br/>

          <LeyendasMovimientos/>

          {
            estadosHorometro.length >0 && (

              estadosHorometro.map((estadoData,index) =>(
                <HorizontalTimeChart
                  heightGrafico={105}
                  key={index}
                  group={"maquinas-op"}
                  series={estadoData}
                  turno={getMinMax(turnoSelec)}
                  numCategorias={1}
                  abrirModalJustificaciones={handleClickOpen}
                  MostrarLabelCompleto={true}
                  fullHeight={true}
                />
              )) 
            )
          }
          {
            ["Camion", "LHD"].includes(tipoFlota) && (
              <>
                <br/>
                <br/>
                <SectionHeader 
                  title={t("prod_por_equipo")}
                  subtitle={`${t("ultima_actualizacion")} ${format(fechaActual, "HH:mm")} hrs`}
                  icon={<img src={tipoFlota==="Camion"?IconoProdEquipoCamion:IconoProdEquipoLHD} width="45px" alt=" Icono Produccion por Equipo"/>}
                />
                <br/>
                <br/>
                <Grid container justifyContent="space-between" alignItems="center" sx={{padding:"0px 30px"}}>
                  <Grid item lg={1} md={2} xs={2}>
                    <span className={classes.prodDes} style={{fontWeight:"bold"}}>
                      {tipoFlota =="LHD"  ?  t("cargas") : t("vueltas")}
                    </span>
                  </Grid>
                </Grid>
                
                <CategoricalBarChart 
                  dataSeries = {(tipoFlota==="Camion") ? prodDataSeriesMaquinas: prodDataSeriesLHD}
                  numCategorias={(tipoFlota==="Camion")?(datosMina?.camiones.length):(datosMina?.scoops.length)}
                  titulo={t("vueltas")}
                  loading={loadingVueltas}
                />

                <br/>
                <br/>
                
                {
                  (esMinaSubterrane == true && ["Camion"].includes(tipoFlota)) && (
                  <>
                    <Grid container direction={"column"}>
                    <Grid item>
                     
                        <SectionHeader 
                          title={idMina == 24 ?  t("prod_por_mina") : t("prod_por_portales")}
                          subtitle={`${t("ultima_actualizacion")} ${format(fechaActual, "HH:mm")} hrs`}
                          icon={<img src={IconoProdPortales} width="35px" alt=" Icono Produccion por Portal"/>}
                        />
                        <br/>
                        <br/>
                        <Grid 
                          container
                          justifyContent="center"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid item lg={3} md={3} xs={3} sx={{padding:"3px 0px !important",borderRight:"2px solid #d7d7d7"}}>
                            <Grid container justifyContent="center" alignItems="center">
                              <span 
                                style={{
                                  margin:"0px 5px 0px 0px",
                                  height:"13px",
                                  width:"13px",
                                  backgroundColor: "#00b7c4",
                                  borderRadius:"50%",
                                  display: "inline-block"
                                }}
                              />
                              <span className={classes.prodDes} style={{marginLeft:"3px"}}>
                                {tipoFlota =="LHD"  ?  t("cargas") : t("vueltas")}
                              </span>
                            </Grid>
                          </Grid>
                          {tipoFlota !="LHD"  &&  (
                            <Grid item lg={3} md={3} xs={3}sx={{padding:"3px 0px 3px 20px !important"}}>
                              <Grid container justifyContent="center" alignItems="center">
                                <span 
                                  style={{
                                    margin:"0px 5px 0px 0px",
                                    height:"13px",
                                    width:"13px",
                                    backgroundColor: "#2f4554",
                                    borderRadius:"50%",
                                    display: "inline-block"
                                  }}
                                />
                                <span className={classes.prodDes} style={{marginLeft:"3px"}}>
                                  {t("toneladas")}
                                </span>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                        <br/>
                        <br/>
                        <Grid container justifyContent="space-between" alignItems="center" sx={{padding:"0px 30px"}}>
                          <Grid item lg={1} md={2} xs={2}>
                            <span className={classes.prodDes} style={{fontWeight:"bold"}}>
                              {tipoFlota =="LHD"  ?  t("cargas") : t("vueltas")}
                            </span>
                          </Grid>
                          <Grid item lg={1} md={2} xs={2}>
                            <span className={classes.prodDes} style={{fontWeight:"bold"}}>
                              {t("toneladas")}
                            </span>
                          </Grid>
                        </Grid>
                        <CategoricalMultiBarChart
                          colores={["#00b7c4","#2f4554"]}
                          dataSeries = {prodDataSeriesPortales}

                        />
                         <br/>
                         <br/>
                      </Grid>
                      {
                        (idMina == 24 && tipoFlota !="LHD") && (

                      <Grid item>
                          <SectionHeader 
                            title={t("prod_diferenciada")}
                            subtitle={`${t("ultima_actualizacion")} ${format(fechaActual, "HH:mm")} hrs`}
                            //icon={<img src={IconoProdPortales} width="35px" alt=" Icono Produccion por Portal"/>}
                            icon={<DataThresholdingOutlinedIcon style={{ fontSize: "35px" }} fontSize="35px"/>}
                          />
                          <br/>
                          <br/>
                          <Grid 
                            container
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                          >
                            <Grid item lg={3} md={3} xs={3} sx={{padding:"3px 0px !important",borderRight:"2px solid #d7d7d7"}}>
                              <Grid container justifyContent="center" alignItems="center">
                                <span 
                                  style={{
                                    margin:"0px 5px 0px 0px",
                                    height:"13px",
                                    width:"13px",
                                    backgroundColor: "rgb(255, 69, 96)",
                                    borderRadius:"50%",
                                    display: "inline-block"
                                  }}
                                />
                                <span className={classes.prodDes} style={{marginLeft:"3px"}}>
                                  {t("vueltas")}
                                </span>
                              </Grid>
                            </Grid>
                            <Grid item lg={3} md={3} xs={3}sx={{padding:"3px 0px 3px 20px !important"}}>
                              <Grid container justifyContent="center" alignItems="center">
                                <span 
                                  style={{
                                    margin:"0px 5px 0px 0px",
                                    height:"13px",
                                    width:"13px",
                                    backgroundColor: "rgb(254, 176, 25)",
                                    borderRadius:"50%",
                                    display: "inline-block"
                                  }}
                                />
                                <span className={classes.prodDes} style={{marginLeft:"3px"}}>
                                  {t("toneladas")}
                                </span>
                              </Grid>
                            </Grid>
                          </Grid>
                          <br/>
                          <br/>
                          <Grid container justifyContent="space-between" alignItems="center" sx={{padding:"0px 30px"}}>
                            <Grid item lg={1} md={2} xs={2}>
                              <span className={classes.prodDes} style={{fontWeight:"bold"}}>
                                {t("vueltas")}
                              </span>
                            </Grid>
                            <Grid item lg={1} md={2} xs={2}>
                              <span className={classes.prodDes} style={{fontWeight:"bold"}}>
                                {t("toneladas")}
                              </span>
                            </Grid>
                          </Grid>
                          <CategoricalMultiBarChart
                            colores={["rgb(255, 69, 96)","rgb(254, 176, 25)"]}
                            dataSeries = {prodDataSeriesTipoVuelta}
                          />
                        </Grid>
                        )
                      }
                  
                    </Grid>
                  </>
                  )
                }
              </>
            )
          }

        </>}
        {(listaTiposDeFlota.includes("Jumbo") && datosMina.jumbos.length>0 &&  (listadoFlotaSeleccionada && listadoFlotaSeleccionada.length > 0)) && tipoFlota==="Jumbo" &&
          <div style={{display:tipoFlota==="Jumbo"?"block":"none"}}>
           <JumboView
              idAreaOp={idMina}
              fecha={fechaActual}
              tipoTurno={tipoTurno}
              minMax={getMinMax(turnoSelec)}
              onChangeTurno={onChangeTurno}
              loadingMovimientos={loadingMovimientos}
              tipoFlota={tipoFlota}
              setReloadCharts = {setReloadCharts}
              metas = {metas}
              listadoFlotaSeleccionada={listadoFlotaSeleccionada}
              movimientoPercutor={estadosHorometro}
              loadingMovimientosMaquinasParalelos={loadingMovimientosMaquinasParalelos}
              setLoadingMovimientosMaquinasParalelos={setloadingMovimientosMaquinasParalelos}
            /> 
          </div>
        }

    {(tipoFlota == "todos" && datosMina.turnos) &&
          <Grid>
           <AllMaquinasView
             idAreaOp={idMina}
             fecha={fechaActual}
             tipoTurno={tipoTurno}
             minMax={getMinMax(turnoSelec)}
             turnos={datosMina.turnos}
             maquinas ={ListadoMaquinas}
             loadingMaquinas ={loadingMaquinas}
           />
          
          </Grid>
        }

        {(listaTiposDeFlota.includes("Bolter") && datosMina.bolters.length>0) && (listadoFlotaSeleccionada && listadoFlotaSeleccionada.length > 0) && tipoFlota==="Bolter" &&
          <div style={{display:tipoFlota==="Bolter"?"block":"none"}}>
            <JumboView
              idAreaOp={idMina}
              fecha={fechaActual}
              tipoTurno={tipoTurno}
              minMax={getMinMax(turnoSelec)}
              onChangeTurno={onChangeTurno}
              loadingMovimientos={loadingMovimientos}
              tipoFlota={tipoFlota}
              listadoFlotaSeleccionada={listadoFlotaSeleccionada}
              movimientoPercutor={estadosHorometro}
              setLoadingMovimientosMaquinasParalelos={setloadingMovimientosMaquinasParalelos}
              loadingMovimientosMaquinasParalelos={loadingMovimientosMaquinasParalelos}
            />
          </div>
        }
                 
        </Card>

        <ModalDescargaPlanilla idMina={idMina} nombreMina={nombreMina} open={openModalDescarga} onClickClose={setOpenModalDescarga} esSubterranea={esMinaSubterrane}/>

      </Container>

    </>}
    </>
  )
} 

export default Operaciones;