import {
  Box,
  Grid
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconoVueltasLight from '../../assets/icons/icono-vueltas.png';
import IconoTonelajeLight from '../../assets/icons/icono-tonelaje.png';
import IconoVueltasDark from '../../assets/icons/icono-vueltas-dark.png';
import IconoTonelajeDark from '../../assets/icons/icono-tonelaje-dark.png';
import { Divider } from "@mui/material";
import '../../translator';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  selBox: {
    width: "100%",
    backgroundColor: '#2f4554',
    borderRadius: "2px",
    padding:"30px 15px",
    pointerEvents:"none"
  },
  unSelBox: {
    width: "100%",
    backgroundColor: '#f4f4f4',
    borderRadius: "2px",
    padding:"30px 15px",
    cursor:"pointer",
    '&:hover': {
      backgroundColor: '#e9e9e9'
    }
  },
  selDescripcion: {
    color: "#fff",
    fontSize: "12px !important",
    fontWeight:"bold !important"
  },
  unSelDescripcion: {
    color: "#2f4554",
    fontSize: "12px !important",
    fontWeight:"bold !important"
  },
  selValue:{
    color: "#fff",
    fontSize: "24px !important",
    fontWeight:"bold !important"
  },
  unSelValue:{
    color: "#00b7c4",
    fontSize: "24px !important",
    fontWeight:"bold !important"
  },
  selValueDes:{
    color: "#fff",
    fontSize: "12px !important",
  },
  unSelValueDes:{
    color: "#2f4554",
    fontSize: "12px !important",
  }
}));

const ResumenDiarioCard = ({id,titulo1,titulo2,dataActividad,tonelaje,isSelect, onClick, tipoFlota=""}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  let tituloActividad = tipoFlota == "LHD" ? t("cargas") : t("vueltas");
  return(
    <>        
        <Box className={isSelect?classes.selBox:classes.unSelBox} onClick={()=>onClick(id)}>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid item lg={3} md={3} xs={3}>
              <div className={isSelect?classes.selDescripcion:classes.unSelDescripcion}>
                <div>{`${titulo1||""} `}</div><div>{titulo2||""}</div>
              </div>
            </Grid>
            <Grid item lg={3} md={3} xs={3}>
              <Grid
                container
                direction="column"
                alignItems="center"
              >
                <div className={isSelect?classes.selValue:classes.unSelValue}>
                  {dataActividad}
                </div>
                <div className={isSelect?classes.selValueDes:classes.unSelValueDes}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                  >
                    <img 
                      style={{marginRight:"4px"}} 
                      src={isSelect?IconoVueltasLight:IconoVueltasDark} 
                      width="15px" 
                      alt=" icono vueltas"
                    />
                    {tituloActividad}
                  </Grid>
                </div>
              </Grid>
            </Grid>
            {
              tipoFlota != "LHD" && (
                <>
                  <Divider orientation="vertical" flexItem sx={{background:isSelect?"#fff":"#d7d7d7"}}/>

                  <Grid item lg={3} md={3} xs={3}>
                    <Grid
                      container
                      direction="column"
                      alignItems="center"
                      sx={{marginLeft:"10%"}}
                    >
                      <div className={isSelect?classes.selValue:classes.unSelValue}>
                        {tonelaje}
                      </div>
                      <div className={isSelect?classes.selValueDes:classes.unSelValueDes}>
                        <Grid
                          container
                          direction="row"
                          alignItems="center"
                        >
                          <img 
                            style={{marginRight:"4px"}} 
                            src={isSelect?IconoTonelajeLight:IconoTonelajeDark} 
                            width="15px" 
                            alt=" icono vueltas"
                          />
                          {t("tonelaje")}
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </>
              )
            }
          </Grid>
        </Box>
      {/* </ThemeProvider> */}
    </>
  )
}

export default ResumenDiarioCard;