export const separarPorMiles = (num) => {
  let stringNum = Number(num).toLocaleString('en-US');
  stringNum = stringNum.split(".")
  return `${stringNum[0].replaceAll(",",".")}`;
}

export const formatoDecimales = (num) => {
  return (Math.round((num)*10)/10).toLocaleString('de-DE')
}

export const formatearAPorcentaje = (num) => {
  return (Math.round((num)*10000)/100).toLocaleString('de-DE')
}

export const formatearCantidadDecimales = (num, decimales) =>{
  return num.toFixed(decimales)
}

