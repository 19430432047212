import { format } from "date-fns";


export function obtenerFechaEnFormato() {
  // Obtén la fecha actual
  const fechaActual = new Date();

  // Formatea la fecha en el formato "dia-mes-año"
  const fechaFormateada = format(fechaActual, 'dd-MM-yyyy');

  return fechaFormateada;
}

export function decimalToHexadecimal(decimalNumber) {
  // Convierte el número a hexadecimal
  const hexadecimalString = decimalNumber.toString(16).toUpperCase();

  return hexadecimalString;
}

export function translateMachineName(name, t, idMina = 0){
  const [type, id] = name?.split(' ');
  if(type.toLowerCase() == "dumper" && idMina == 24){
    const translatedType = t(type.toLowerCase());
    return `${translatedType} ${id}`;
  }else{
    return name
  }
}